/*
 *Author :chenlijie
 *@Desc: 路由页面
 *Date:2022-03-02
 */
import Vue from "vue"
import VueRouter from "vue-router"
import Router from "vue-router"

const original = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return original.call(this, location).catch((err) => err)
}
Vue.use(VueRouter)

export const constantRoutes = [
	{
		path: "/",
		hidden: true,
		redirect: "/cathaya-home",
	},
	// 增加一个隐私政策的URL allen 20240807
	{
		path: "/protection-policy",
		component: () => import("@/view/policy"),
		name: "protection-policy",
	},
	// cathaya首页
	{
		path: "/cathaya-home",
		component: () => import("@/view/home"),
		name: "cathayaHome",
	},
	{
		path: "/cathayaSolution",
		component: () => import("@/view/solution"),
	},

	/************ service start*****************/
	{
		path: "/cathaya-services",
		component: () => import("@/view/services"),
		name: "cathayaServices",
	},
	{
		path: "/cathaya-customResearch",
		component: () => import("@/view/services/customResearch"),
	},
	{
		path: "/cathaya-qualitative",
		component: () => import("@/view/services/customResearch/qualitative"),
	},
	{
		path: "/cathaya-quantitative",
		component: () => import("@/view/services/customResearch/quantitative"),
	},
	{
		path: "/cathaya-industryAnalysis",
		component: () => import("@/view/services/customResearch/industryAnalysis"),
	},
	// global Project Management
	{
		path: "/cathaya-globalProject",
		component: () => import("@/view/services/globalProject"),
	},
	{
		path: "/cathaya-sampleService",
		component: () => import("@/view/services/globalProject/panelSampleService"),
	},
	{
		path: "/cathaya-fieldworkManagement",
		component: () => import("@/view/services/globalProject/globalFieldworkManagement"),
	},
	{
		path: "/cathaya-facilityRental",
		component: () => import("@/view/services/globalProject/facilityRental"),
	},
	{
		path: "/cathaya-translationServices",
		component: () => import("@/view/services/globalProject/translationServices"),
	},
	{
		path: "/cathaya-localizationSupport",
		component: () => import("@/view/services/globalProject/localizationSupport"),
	},
	{
		path: "/cathaya-multiculturalResearch",
		component: () => import("@/view/services/globalProject/multiculturalResearch"),
	},
	// Data Solutions
	{
		path: "/cathaya-dataSolutions",
		component: () => import("@/view/services/dataSolutions"),
	},
	/************ service end*****************/
	{
		path: "/cathaya-solutions",
		component: () => import("@/view/solutions/index"),
		name: "cathayaSolutions",
	},
	// insight start
	{
		path: "/cathaya-insights",
		component: () => import("@/view/insights"),
	},
	{
		path: "/cathaya-science",
		component: () => import("@/view/insights/science"),
	},
	{
		path: "/cathaya-details",
		component: () => import("@/view/insights/details"),
	},
	{
		path: "/cathaya-search",
		component: () => import("@/view/insights/search"),
	},
	// insight end
	{
		path: "/cathaya-contact",
		component: () => import("@/view/contact/about"),
	},
	// contact about us
	{
		path: "/cathaya-about",
		component: () => import("@/view/contact/about"),
	},
	// contact clients
	{
		path: "/cathaya-clients",
		component: () => import("@/view/contact/clients"),
	},
	// news letter
	{
		path: "/cathaya-newsletter",
		component: () => import("@/view/newsletter"),
	},
	// contact career
	{
		path: "/cathaya-career",
		component: () => import("@/view/contact/career"),
	},
	//
	{
		path: "/cathaya-careerForm",
		component: () => import("@/view/contact/career/careerForm"),
	},
	// contact team
	{
		path: "/cathaya-team",
		component: () => import("@/view/team"),
	},
	{
		path: "/cathaya-localizationSupport/details",
		component: () => import("@/view/services/globalProject/localizationSupport/details/index.vue"),
	},
	// {
	//   path: '/cathaya-team-moderatorInfo',
	//   component: () => import('@/view/team/moderatorInfo')
	// },
	// contact team second
	{
		path: "/cathaya-team-info",
		component: () => import("@/view/team/teamInfo"),
	},
	// data solutions advance
	{
		path: "/cathaya-data-solutions-advance",
		component: () => import("@/view/datasolutions/advance"),
	},
	//
	{
		path: "/cathaya-data-solutions-advance-children",
		component: () => import("@/view/datasolutions/advanceChildren"),
	},
	// data solutions software
	{
		path: "/cathaya-data-solutions-software",
		component: () => import("@/view/datasolutions/software"),
	},
	// data solutions survey
	{
		path: "/cathaya-data-solutions-survey",
		component: () => import("@/view/datasolutions/survey"),
	},
	// 隐私政策
	{
		path: "/cathaya-privacy-policy",
		component: () => import("@/view/privacyPolicy"),
	},
	{
		path: "/cathaya-global-data-protection-policies",
		component: () => import("@/view/privacyPolicy/protectionPolicies.vue"),
	},
	//Email
	{
		path: "/cathaya-email",
		component: () => import("@/view/email"),
	},
	//get quote获取报价
	{
		path: "/cathaya-getquote",
		component: () => import("@/view/getQuote"),
	},
	//newsLetterEmail
	{
		path: "/cathaya-newsletter-email",
		component: () => import("@/view/newsLetterEmail"),
	},
	{
		path: "/cathaya-newsletter0116",
		component: () => import("@/view/newsLetterEmail/newsletter0116.vue"),
	},
	{
		path: "/cathaya-newsletter0131",
		component: () => import("@/view/newsLetterEmail/newsletter0131.vue"),
	},
	{
		path: "/cathaya-newsletter0215",
		component: () => import("@/view/newsLetterEmail/newsletter0215.vue"),
	},
	{
		path: "/cathaya-newsletter0228",
		component: () => import("@/view/newsLetterEmail/newsletter0228.vue"),
	},
	{
		path: "/cathaya-newsletter0315",
		component: () => import("@/view/newsLetterEmail/newsletter0315.vue"),
	},
	{
		path: "/cathaya-newsletter0331",
		component: () => import("@/view/newsLetterEmail/newsletter0331.vue"),
	},
	{
		path: "/cathaya-newsletter0415",
		component: () => import("@/view/newsLetterEmail/newsletter0415.vue"),
	},
	{
		path: "/cathaya-newsletter0502",
		component: () => import("@/view/newsLetterEmail/newsletter0502.vue"),
	},
	{
		path: "/cathaya-newsletter0516",
		component: () => import("@/view/newsLetterEmail/newsletter0516.vue"),
	},
	{
		path: "/cathaya-newsletter0531",
		component: () => import("@/view/newsLetterEmail/newsletter0531.vue"),
	},
	{
		path: "/cathaya-newsletter0615",
		component: () => import("@/view/newsLetterEmail/newsletter0615.vue"),
	},
	{
		path: "/cathaya-newsletter0704",
		component: () => import("@/view/newsLetterEmail/newsletter0704.vue"),
	},
	{
		path: "/cathaya-newsletter0718",
		component: () => import("@/view/newsLetterEmail/newsletter0718.vue"),
	},
	{
		path: "/cathaya-newsletter0801",
		component: () => import("@/view/newsLetterEmail/newsletter0801.vue"),
	},
	{
		path: "/cathaya-newsletter0815",
		component: () => import("@/view/newsLetterEmail/newsletter0815.vue"),
	},
	{
		path: "/cathaya-newsletter0831",
		component: () => import("@/view/newsLetterEmail/newsletter0831.vue"),
	},
	{
		path: "/cathaya-newsletter0914",
		component: () => import("@/view/newsLetterEmail/newsletter0914.vue"),
	},
	{
		path: "/cathaya-newsletter0928",
		component: () => import("@/view/newsLetterEmail/newsletter0928.vue"),
	},
	{
		path: "/cathaya-newsletter1017",
		component: () => import("@/view/newsLetterEmail/newsletter1017.vue"),
	},
	{
		path: "/cathaya-newsletter1031",
		component: () => import("@/view/newsLetterEmail/newsletter1031.vue"),
	},
	{
		path: "/cathaya-newsletter1116",
		component: () => import("@/view/newsLetterEmail/newsletter1116.vue"),
	},
	{
		path: "/cathaya-newsletter1130",
		component: () => import("@/view/newsLetterEmail/newsletter1130.vue"),
	},
	{
		path: "/cathaya-newsletter1214",
		component: () => import("@/view/newsLetterEmail/newsletter1214.vue"),
	},
	{
		path: "/cathaya-newsletter1221",
		component: () => import("@/view/newsLetterEmail/newsletter1221.vue"),
	},
	{
		path: "/cathaya-newsletter0118",
		component: () => import("@/view/newsLetterEmail/newsletter0118.vue"),
	},
	{
		path: "/cathaya-newsletter0209",
		component: () => import("@/view/newsLetterEmail/newsletter0209.vue"),
	},
	{
		path: "/cathaya-newsletter0229",
		component: () => import("@/view/newsLetterEmail/newsletter0229.vue"),
	},
	{
		path: "/cathaya-newsletter0319",
		component: () => import("@/view/newsLetterEmail/newsletter0319.vue"),
	},
	{
		path: "/cathaya-newsletter0328",
		component: () => import("@/view/newsLetterEmail/newsletter0328.vue"),
	},
	{
		path: "/cathaya-newsletter0416",
		component: () => import("@/view/newsLetterEmail/newsletter0416.vue"),
	},
	{
		path: "/cathaya-newsletter0514",
		component: () => import("@/view/newsLetterEmail/newsletter0514.vue"),
	},
	{
		path: "/cathaya-newsletter0528",
		component: () => import("@/view/newsLetterEmail/newsletter0528.vue"),
	},
	{
		path: "/cathaya-newsletter0618",
		component: () => import("@/view/newsLetterEmail/newsletter0618.vue"),
	},
	{
		path: "/cathaya-newsletter240709",
		component: () => import("@/view/newsLetterEmail/newsletter240709.vue"),
	},
	{
		path: "/cathaya-newsletter240801",
		component: () => import("@/view/newsLetterEmail/newsletter240801.vue"),
	},
	{
		path: "/cathaya-newsletter241015",
		component: () => import("@/view/newsLetterEmail/newsletter241015.vue"),
	},
	{
		path: "/cathaya-newsletter241107",
		component: () => import("@/view/newsLetterEmail/newsletter241107.vue"),
	},
	{
		path: "/cathaya-newsletter241212",
		component: () => import("@/view/newsLetterEmail/newsletter241212.vue"),
	},
	{
		path: "*",
		component: () => import("@/view/404"),
	},
]
const createRouter = () =>
	new Router({
		mode: "history", // url模式
		scrollBehavior: () => ({ y: 0 }),
		routes: constantRoutes,
	})

const router = createRouter()

export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}

export default router

//  加上链接
